import React, {Suspense, lazy} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import { ROUTE_CONSTANTS } from "../utils/RouteConstants";
import LoadingSplash from "../components/LoadingSplash";
const Faq = lazy(() => import("../components/Dashboard/Faq"));
const PasswordManagement = lazy(() => import("../components/Dashboard/PasswordManagement"));
const PolicyPage = lazy(() => import("../components/Dashboard/PolicyPage"));
const TrainingExperienceNew = lazy(() => import("../components/Profile/Dashboard/TrainingExperienceNew"));
const EventsPage = lazy(() => import("../pages/EventsPage")); 
const ComingSoon = lazy(() => import("../global/ComingSoon"));
const EventsSearchPage = lazy(() => import("../pages/EventsSearchPage"));
const ForgotPasswordPage = lazy(() => import("../pages/ForgotPasswordPage"));
const Login = lazy(() => import("../pages/Login"));
const ManageProfilePage = lazy(() => import("../pages/ManageProfilePage"));
const PaymentMethodPage = lazy(() => import("../pages/PaymentMethodPage"));
const PersonalDetailPage = lazy(() => import("../pages/PersonalDetailPage"));
const Register = lazy(() => import("../pages/Register"));
const ViewListingPage = lazy(() => import("../pages/ViewListingPage"));
const ListingDetail = lazy(() => import("../components/Dashboard/ListingDetail"));
const DraftsPage = lazy(() => import("../pages/DraftsPage"));
const ProfileMenuPage = lazy(() => import("../pages/ProfileMenuPage"));
const BookingEvent = lazy(() => import("../components/Dashboard/BookingEvent"));
const PromotionPage = lazy(() => import("../pages/PromotionPage"));
const Earnings = lazy(() => import("../components/Dashboard/Earnings"));
const EarnBookingPage = lazy(() => import("../pages/EarnBookingPage"));
const Welcome = lazy(() => import("../pages/Welcome"));
const PurchaseListing = lazy(() => import("../components/Dashboard/PurchaseListing"));
const ReviewScreen = lazy(() => import("../components/Dashboard/Review"));
const Subscription = lazy(() => import("../components/Dashboard/Subscription/Subscription"));
const ChatPage = lazy(() => import("../pages/ChatPage"));
const MyBookings = lazy(() => import("../components/Dashboard/MyBookings"));
const Profile = lazy(() => import("../components/UserProfile/Profile"));
const MyListingsPage = lazy(() => import("../pages/MyListingPage"));
const NotificationPage = lazy(() => import("../pages/Notification"));
const StripeSuccess = lazy(() => import("../components/Stripe/StripeSuccess"));
const StripeFailed = lazy(() => import("../components/Stripe/StripeFailed"));
const BookingDetails = lazy(() => import("../components/Dashboard/BookingDetails"));
const EventBookingPage = lazy(() => import("../pages/EventBookingPage"));
const ListingDetailTwo = lazy(() => import("../components/Dashboard/ListingDetailTwo"));
const ListingDetailGallery = lazy(() => import("../components/Dashboard/ListingDetailGallery"));
const PromotionDetails = lazy(() => import("../components/Promotion/PromotionDetails"));
const LandingPageNew = lazy(() => import("../pages/LandingPage"));
const InterestNew = lazy(() => import("../components/Profile/Dashboard/IntersetsNew"));
const BankAccountStripe = lazy(() => import("../components/Profile/Dashboard/BankAccountStripe"));
const FavouriteNew = lazy(() => import("../components/Profile/Dashboard/FavouriteNew"));
const ConnectConnections = lazy(() => import("../components/Connect/ConnectConnections"));
const ConnectRequests = lazy(() => import("../components/Connect/ConnectRequests"));
const ConnectSuggestions = lazy(() => import("../components/Connect/ConnectSuggestions"));
const ConnectFavourites = lazy(() => import("../components/Connect/ConnectFavourites"));
const ConnectFlagged = lazy(() => import("../components/Connect/ConnectFlagged"));
const ConnectZone = lazy(() => import("../components/Connect/ConnectZone"));
const BusinessDetailPage = lazy(() => import("../pages/BusinessDetailPage"));
const MyTeamListing = lazy(() => import("../components/Profile/Dashboard/MyTeamListing"));
const TeamListingDetails = lazy(() => import("../components/Profile/Dashboard/TeamListingDetails"));
const ConnectUserProfile = lazy(() => import("../pages/ConnectUserProfile/ConnectUserProfile"));
const PaymentClose = lazy(() => import("../pages/PaymentClose"));
const AddListingPage = lazy(() => import("../pages/AddListingPage"));
const SelectListingTypePage = lazy(() => import("../pages/SelectListingTypePage"));
const ModifyListingPage = lazy(() => import("../pages/ModifyListingPage"));
const MemberType = lazy(() => import("../components/Profile/MemberType"));
const ProtectedRoutes = lazy(() => import("./ProtectedRoutes"));
const OnboardingRoutes = lazy(() => import("./OnboardingRoutes"));
const ConnectWrapper = lazy(() => import("../components/Connect/ConnectComponents/ConectWrapper"));
const SpecialRoutes = lazy(() => import("./SpecialRoutes"));
const OpenRoutes = lazy(() => import("./OpenRoutes"));

const routes = [
  {path: ROUTE_CONSTANTS.landing, element: <EventsPage />, type: "special"},
  {
    path: ROUTE_CONSTANTS.search,
    element: <EventsSearchPage />,
    type: "public",
  },
  {path: ROUTE_CONSTANTS.policies, element: <PolicyPage />, type: "public"},
  {
    path: ROUTE_CONSTANTS.about_us,
    element: <LandingPageNew />,
    type: "public",
  },
  {
    path: ROUTE_CONSTANTS.dashboard,
    element: <Navigate to={ROUTE_CONSTANTS.landing} />,
    type: "public",
  },
  { path: ROUTE_CONSTANTS.register, element: <Register />, type: "open" },
  {
    path: ROUTE_CONSTANTS.forgot_password,
    element: <ForgotPasswordPage />,
    type: "public",
  },
  { path: ROUTE_CONSTANTS.login, element: <Login />, type: "open" },
  {
    path: ROUTE_CONSTANTS.events,
    element: <Navigate to={ROUTE_CONSTANTS.landing} />,
    type: "public",
  },
  {path: "events/:id", element: <ListingDetail />, type: "public"},
  {
    path: "/listing-two/:id",
    element: <ListingDetailTwo />,
    type: "public",
  },
  {
    path: "/listing-gallery/:id",
    element: <ListingDetailGallery />,
    type: "public",
  },

  {path: ROUTE_CONSTANTS.welcome, element: <Welcome />, type: "onboarding"},
  {
    path: ROUTE_CONSTANTS.select_member_type,
    element: <MemberType />,
    type: "onboarding",
  },
  {
    path: ROUTE_CONSTANTS.manage_profile,
    element: <ManageProfilePage />,
    type: "onboarding",
  },
  {
    path: `${ROUTE_CONSTANTS.events_booking}/:id`,
    element: <EventBookingPage />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.my_created_listing,
    element: <MyListingsPage />,
    type: "protected",
  },
  {path: "events/:id/review", element: <ReviewScreen />, type: "protected"},

  {
    path: ROUTE_CONSTANTS.bank_accounts,
    element: <BankAccountStripe />,
    type: "protected",
  },
  {
    path: "/our-mates/:id",
    element: <ConnectUserProfile />,
    type: "protected",
  },
  {path: "/payment-status", element: <PaymentClose />, type: "public"},
  {
    path: ROUTE_CONSTANTS.personal_details,
    element: <PersonalDetailPage />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.business_details,
    element: <BusinessDetailPage />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.profile_menu,
    element: <ProfileMenuPage />,
    type: "protected",
  },
  {path: `/user/:id`, element: <Profile />, type: "protected"},
  {
    path: ROUTE_CONSTANTS.intrest,
    element: <InterestNew />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.my_earning,
    element: <Earnings />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.favourites,
    element: <FavouriteNew />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.experience,
    element: <TrainingExperienceNew />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.password_management,
    element: <PasswordManagement />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.subscription,
    element: <Subscription />,
    type: "protected",
  },
  {path: ROUTE_CONSTANTS.faq, element: <Faq />, type: "protected"},
  {
    path: ROUTE_CONSTANTS.payment_methods,
    element: <PaymentMethodPage />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.view_listing,
    element: <ViewListingPage />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.stripeconnect_success,
    element: <StripeSuccess />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.stripeconnect_failed,
    element: <StripeFailed />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.promotion,
    element: <PromotionPage />,
    type: "protected",
  },
  {
    path: `${ROUTE_CONSTANTS.promotion}/:id`,
    element: <PromotionDetails />,
    type: "protected",
  },
  {path: ROUTE_CONSTANTS.drafts, element: <DraftsPage />, type: "protected"},
  {
    path: `${ROUTE_CONSTANTS.bookingEvent}/:event_id/:booking_id`,
    element: <BookingEvent />,
    type: "protected",
  },
  {
    path: `${ROUTE_CONSTANTS.earn_booking}/:listing_id`,
    element: <EarnBookingPage />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.notifications,
    element: <NotificationPage />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.purchase_listing,
    element: <PurchaseListing />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.team_membership,
    element: <MyTeamListing />,
    type: "protected",
  },
  {path: ROUTE_CONSTANTS.chat, element: <ChatPage />, type: "protected"},
  {
    path: ROUTE_CONSTANTS.my_booking,
    element: <MyBookings />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.booking_details,
    element: <BookingDetails />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.team_listing_details,
    element: <TeamListingDetails />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.select_listing,
    element: <SelectListingTypePage />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.new_listing,
    element: <AddListingPage />,
    type: "protected",
  },
  {
    path: ROUTE_CONSTANTS.modify_listing,
    element: <ModifyListingPage />,
    type: "protected",
  },
  //connect
  {
    path: ROUTE_CONSTANTS.connect,
    element: (
      <ConnectWrapper>
        <ConnectZone />
      </ConnectWrapper>
    ),
    type: "protected",
  },
  {
    path: "/connect/connections",
    element: (
      <ConnectWrapper>
        <ConnectConnections />
      </ConnectWrapper>
    ),
    type: "protected",
  },
  {
    path: "/connect/requests",
    element: (
      <ConnectWrapper>
        <ConnectRequests />
      </ConnectWrapper>
    ),
    type: "protected",
  },
  {
    path: "/connect/flagged",
    element: (
      <ConnectWrapper>
        <ConnectFlagged />
      </ConnectWrapper>
    ),
    type: "protected",
  },
  {
    path: "/connect/suggestions",
    element: (
      <ConnectWrapper>
        <ConnectSuggestions />
      </ConnectWrapper>
    ),
    type: "protected",
  },
  {
    path: "/connect/favourite",
    element: (
      <ConnectWrapper>
        <ConnectFavourites />
      </ConnectWrapper>
    ),
    type: "protected",
  },
];

const RouteStack = () => {
  
  return (
    <Suspense fallback={<LoadingSplash />}>
      <Routes>
        {routes.map(({ path, element, type }) => (
          <Route
            key={path}
            path={path}
            element={
              type === "protected" ? (
                <ProtectedRoutes>{element}</ProtectedRoutes>
              ) : type === "onboarding" ? (
                <OnboardingRoutes>{element}</OnboardingRoutes>
              ) : type === "special" ? (
                <SpecialRoutes>{element}</SpecialRoutes>
              ) : type === "open" ? (
                <OpenRoutes>{element}</OpenRoutes>
              ) : (
                element
              )
            }
          />
        ))}
        <Route path="*" element={<ComingSoon />} />
      </Routes>
    </Suspense>
  );
};

export default RouteStack;
